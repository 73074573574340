<template>
  <div class="usersListVisor">
    <Modal>
      <template v-slot:header>
        <div class="usersListVisor__headerWrapper">
          <div class="usersListVisor__header">
            <h2>{{ title || "Usuarios" }}</h2>
            <iconic class="usersListVisor__close" name="close" @click.native="closeModal" />
          </div>
          <p v-if="detail">{{ detail }}</p>
        </div>
      </template>
      <template v-slot:body>
        <div class="usersListVisor__body">
          <div class="usersListVisor__containerRow" v-for="(user, idx) in users" :key="idx">
            <div class="usersListVisor__contentUser">
              <Avatar class="usersListVisor__avatar" :user="user" />
              <span class="usersListVisor__name">{{ user.artisticName || user.user }}</span>
            </div>
            <span v-if="user.views">{{ formatRepetitions(user.views) }}</span>
          </div>
          <div class="usersListVisor__link" @click="getMore" v-if="hasMore && !isLoading">Ver más</div>
          <div class="spinner" v-if="isLoading"></div>
        </div>
      </template>
      <template v-slot:footer>
        <span></span>
      </template>
    </Modal>
  </div>
</template>

<script>
  import Modal from "../../modals/Modal";

  export default {
    components: { Modal },
    props: ["users", "isLoading", "hasMore", "title", "detail", "closeModal", "getMore"],
    methods: {
      formatRepetitions(number) {
        return `${number} ve${number > 1 ? "ces" : "z"}`;
      },
      addScrollEvent() {
        const body = document.querySelector(".usersListVisor__body");
        this.$global.clickOnBottom({
          el: body,
          onBottom: this.getMore,
          gap: 10,
        });
      },
      exitWithEsc(e) {
        if (e.key === "Escape") {
          this.closeModal();
        }
      },
    },
    beforeMount() {
      document.body.classList.add("onmodal");
    },
    mounted() {
      this.addScrollEvent();
      document.addEventListener("keyup", this.exitWithEsc);
    },
    beforeDestroy() {
      document.removeEventListener("keyup", this.exitWithEsc);
      document.body.classList.remove("onmodal");
    },
  };
</script>

<style lang="scss">
  .usersListVisor {
    .modal-g {
      width: 100%;
      height: 100%;
    }
    .modal-g__wrapper {
      background-color: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
    }
    .modal-g__container {
      width: 100%;
      max-width: 1024px;
      padding: 0;
    }
    &__headerWrapper {
      width: 100%;
      padding: $mpadding;
      padding-bottom: 5px;
    }
    &__header {
      @include Flex(row, space-between);
      width: 100%;
      text-align: end;
    }
    &__close {
      @include Flex(row);
      width: 30px;
      height: 30px;
      color: $primary-color;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &:hover {
        background: #e5e5e5;
      }
    }
    &__body {
      @include Flex(column, flex-start, flex-start);
      width: 100%;
      gap: 15px;
      padding: $mpadding;
      padding-top: 0;
      max-height: 700px;
      overflow-y: auto;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
    }
    &__containerRow {
      @include Flex(row, space-between);
      width: 100%;
      padding: 10px 0;
      border-bottom: 1px solid $alto;
    }
    &__contentUser {
      @include Flex(row);
      gap: 15px;
    }
    &__avatar {
      width: 33px;
      height: 33px;
      border: 1px solid $primary-color;
    }
    &__containerRow:last-child {
      border-bottom: none;
    }
    &__link {
      margin: auto;
      padding: $mpadding/2 0;
      appearance: none;
      text-align: center;
      color: $primary-color;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>
